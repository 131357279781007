@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/avenirnext.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next';
    src: url('../fonts/avenirnextltpro-bold.ttf') format('truetype');
    font-weight: bold;
    font-style: bold;
}

body {
    margin: 0;
    line-height: normal;
    font-family: "Avenir Next", sans-serif !important;
}

p {
    font-size: 1.1rem;
    color: #1e1e1e;
}

.green {
    color: #39A58E;
    font-weight: bold;
}

.btn-primary {
    background-color: #29b99a;
    border-color: #29b99a;
}

.btn-primary:hover {
    background-color: #1a7562;
    border-color: #1a7562;
}
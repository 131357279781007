.espacio-bkg {
    background: url("../../public/images/espacio-bkg.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
    padding: 0;
}

.bkg-light {
    background-color: rgba(0, 0, 0, .7);
    padding: 50px;
    margin: auto;
    min-height: 300px;
}

.bkg-light p.title {
    color: #FFFFFF;
    font-size: 1.5rem;
    width: 100%;
}

.bkg-light p.subtitle {
    color: #FFFFFF;
    font-size: 1.2rem;
    width: 25%;
    margin: auto;
}

@media (max-width: 768px) {
    .bkg-light p.subtitle {
        width: 100%;
    }
}
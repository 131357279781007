.descarga {
  padding: 2.5rem 1rem;
}

.title {
  font-size: 2.5rem;
  color: #364a5e;
  font-weight: bold;
}

.ests-listo-para {
  font-size: 1.5rem;
  color: #1e1e1e;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.qr {
  margin-left: -25px;
}

.install-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #FF4500;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.install-button:hover {
  background-color: #e03e00;
}


@media (max-width: 768px) {
  .title {
    font-size: 2rem;
  }

  .ests-listo-para {
    font-size: 1.25rem;
  }
}
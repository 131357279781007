.servicios {
  padding: 2rem;
  background-color: #fff;
}

h2 {
  color: #29b99a;
  font-weight: bold;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.list-unstyled p {
  margin-top: 1rem;
}
.vector-icon {
    width: 14.675rem;
    height: 3.125rem;
}

.navbar-nav .nav-link {
    font-size: 1rem;
    color: #1e1e1e;
    text-transform: inherit;
    padding: 0.5rem 1rem;
}

.navbar-nav .nav-link:hover {
    text-decoration: underline;
}

@media (max-width: 767px) {
    .navbar {
        margin-top: 1rem;
    }
}
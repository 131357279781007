.caracteristicas {
  padding: 3rem 1rem;
  background-color: #ecf0f1;
}

h2 {
  color: #364a5e;
  font-weight: bold;
}

h4 {
  color: #364a5e;
  font-weight: bold;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
.footer {
    background-color: #364a5e;
    color: #fff;
    padding: 2.5rem 1rem;
  }

  .footer p {
    color: #fff;
    font-size: 1rem;
  }
  
  .follow-us {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .group-icon {
    width: 3.125rem;
    height: 3.125rem;
  }
  
  .profile {
    font-size: 1rem;
    margin-top: 1rem;
  }
  
  @media (max-width: 768px) {
    .follow-us {
      font-size: 1rem;
    }
  
    .group-icon {
      width: 2.5rem;
      height: 2.5rem;
    }
  
    .profile {
      font-size: 0.875rem;
    }
  }
  
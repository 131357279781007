.funcion-container-fluid {
  width: 100%;
  background-color: #ecf0f1;
  padding: 2rem 0;
}

.funcion {
  padding: 2rem 1rem;
}

.cmo-funciona {
  font-size: 2rem;
  color: #1e1e1e;
}

.registrate {
  font-size: 1.25rem;
  font-weight: bold;
}

.cre-tu-cuenta {
  font-size: 1rem;
  color: #1e1e1e;
}

.icon {
  width: 3.25rem;
  height: 3.25rem;
}

.img-extend {
  margin-left: -3rem;
  margin-right: -3rem;
  width: calc(100% + 6rem);
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .img-extend {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    width: calc(100% + 3rem);
  }
}
.banner {
    padding: 2.5rem 1rem;
  }
  
  .title {
    color: #364a5e;
    font-weight: bold;
    font-size: 2.5rem;
  }
  
  .subtitle {
    color: #1e1e1e;
    font-size: 1.5rem;
  }
  
  @media (max-width: 768px) {
    .title {
      font-size: 2rem;
    }
  
    .subtitle {
      font-size: 1.25rem;
    }
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  